/* ==========================================================================
 * Fonts
 * ========================================================================== */

/**
 * Icomoon
 */
@font-face {
  font-family: icomoon;
  src: url("~icomoon-free-npm/Font/IcoMoon-Free.ttf");
  font-weight: normal;
  font-style: normal;
}
