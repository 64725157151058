/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgb(0 0 0 / 54%); }
.material-icons.md-dark.md-inactive { color: rgb(0 0 0 / 26%); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgb(255 255 255 / 100%); }
.material-icons.md-light.md-inactive { color: rgb(255 255 255 / 30%); }
