.dark-theme {
  body,
  main #content,
  #article,
  .card,
  .card-panel,
  .card .card-reveal,
  .card-stacked .preview:not(.preview--default),
  .card .preview:not(.preview--default),
  .collapsible-header,
  .collection,
  .dropdown-content,
  .nav-panel-add,
  .nav-panel-search,
  .side-nav,
  .side-nav .collapsible-body,
  .side-nav.fixed .collapsible-body,
  .tabs {
    background-color: #121212;
  }

  table.striped > tbody > tr:nth-child(2n+1),
  .dropdown-content li {
    background-color: #232323;
  }

  .dropdown-content li:hover,
  .dropdown-content li.active,
  .dropdown-content li.selected,
  .pagination li:not(.active) a:hover,
  .pagination li:not(.active) a:active,
  .pagination li:not(.active) a:focus {
    background-color: #2c2c2c;
  }

  .dropdown-content .divider {
    background-color: #383838;
  }

  .collection {
    border-color: transparent;
  }

  .collection .collection-item {
    border-color: #121212;
  }

  .card:hover,
  .collection .collection-item:hover {
    background-color: #272727;
  }

  main #content,
  #article article,
  #article article h1,
  #article article h2,
  #article article h3,
  #article article h4,
  #article article h5,
  #article article h6,
  .dropdown-content li > a,
  .results a,
  .side-nav li > a,
  .side-nav li > a > i.material-icons {
    color: #dfdfdf;
  }

  .cyan,
  .cyan.darken-1,
  .cyan.darken-2 {
    background-color: #1d1d1d !important;
  }

  .grey-text.text-darken-4 {
    color: #dfdfdf !important;
  }

  #article .chip {
    background-color: #373737;
  }

  .side-nav li.active {
    background-color: #2f2f2f;
  }

  .side-nav li:not(.logo) > a:hover,
  .side-nav .collapsible-header:hover,
  .side-nav.fixed .collapsible-header:hover {
    background-color: #1d1d1d;
  }

  #article {
    box-shadow: 0 0 10px #1d1d1d;
  }

  .card,
  .collection .collection-item {
    background-color: #1d1d1d;
  }

  .card-action {
    background-color: transparent;
  }

  .logo img,
  .preview.preview--default,
  .typo-logo {
    filter: invert(100%);
  }

  .border-bottom,
  .collapsible,
  .collapsible-body,
  .collapsible-header {
    border-color: #222;
  }

  .pagination li.active {
    background-color: #666;
  }

  .hljs,
  #article pre.hljs {
    color: #abb2bf;
    background-color: #282c34;
  }

  @media only screen and (min-width: 992px) {
    #article {
      background-color: #101010;
    }
  }
}
