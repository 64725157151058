@media print {
  /* ### Layout ### */

  body {
    font-family: serif;
    background-color: #fff;
  }

  @page {
    margin: 1cm;
  }

  img {
    max-width: 100% !important;
  }

  /* ### Content ### */

  /* Hide useless blocks */
  body > header,
  #article_toolbar,
  #links,
  #sort,
  body > footer,
  .top_link,
  div.tools,
  header div,
  .messages,
  .entry + .results,
  #slide-out,
  .progress,
  .hide-on-large-only,
  #article > aside,
  #article .mbm a {
    display: none !important;
  }

  main {
    padding-left: 0 !important;
  }

  #article {
    margin: inherit !important;
  }

  article {
    border: none !important;
  }

  /* Add URL after links */
  .vieworiginal a::after {
    content: " (" attr(href) ")";
  }

  /* Add explanation after abbr */
  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  /* Change border on current pager item */
  .pagination span.current {
    border-style: dashed;
  }

  #main {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  #article {
    width: 100%;
  }
}
