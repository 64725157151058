/* ==========================================================================
 * Filters slider
 * ========================================================================== */

#filters {
  button {
    padding: 0;
    width: 100%;
  }

  div.with-checkbox {
    height: 3rem;
    margin-top: 0;
  }
}
